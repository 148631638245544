<template>
  <div>
    <div class="xpress">
      <SelectEnv
        :visible="isEnvVisible"
        v-on:orderType="orderType"
        :isXpress="isXpress"
        v-on:backMenu="backMenu"
      />
    </div>
  </div>
</template>
<script>
import SelectEnv from "@/components/Xpress/SelectEnv";
export default {
  name: "XpressEnv",
  components: {
    SelectEnv,
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    isXpress() {
      return this.$store.getters.isXpress;
    },
    feeOrder() {
      return this.$route.params.fee;
    },
    isEnvVisible() {
      return !this.$store.getters.isNewOrder && this.visible;
    },
  },
  methods: {
    orderType(value) {
      this.$router.push({
        name: "XpressMenu",
        params: { orderType: value, fee: this.feeOrder },
      });
    },
    backMenu() {
      this.$router.push("/menu");
    },
  },
};
</script>
