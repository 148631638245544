<template>
  <a-modal
    v-model="visible"
    class="modal-user create-user modal-select-type"
    title="Menú"
    :maskClosable="false"
  >
    <div class="modal-content">
      Selecciona el tipo de orden
      <a-row class="modal-order-xpress-type" type="flex" justify="center">
        <a-col :span="10">
          <a-button
            @click="setOrderType('XPRESS')"
            :disabled="setXpressDisabled()"
            class="order-type"
          >
            Express
          </a-button>
        </a-col>
        <a-col :span="10">
          <!-- <a-button @click="setOrderType('AUTO')" class="order-type">
            Autopedido
          </a-button> -->
        </a-col>
      </a-row>
    </div>
    <template slot="footer">
      <a-button key="back" @click="backMenu"> Cancelar </a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "SelectEnv",
  props: {
    visible: {
      default: true,
    },
    isXpress: null,
  },
  methods: {
    setXpressDisabled() {
      return !this.isXpress;
    },
    setOrderType(type) {
      this.$emit("orderType", type);
    },
    backMenu() {
      this.$emit("backMenu");
    },
  },
};
</script>
